import { saveAs } from 'file-saver';
import XLSX, { WorkBook, WorkSheet } from 'sheetjs-style'

interface ICell {
  v: Date | number | boolean | string;
  t: string;
  z: string;
}

export class Workbook implements WorkBook {
  SheetNames: string[] = [];
  Sheets: { [sheet: string]: WorkSheet, } = {};
}

const datenum = (date: Date) => {
  return (+date - +new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

export const sheetFromDataArray = (data: any) => {
  const ws: { [key: string]: any, } = {};
  const range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  for (let R = 0; R !== data.length; ++R) {
    for (let C = 0; C !== data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell: ICell = {
        v: data[R][C],
        t: '',
        z: ''
      };
      if (cell.v == null) continue;
      const cellRef = XLSX.utils.encode_cell({
        c: C,
        r: R
      });
      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF.get_table()[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';
      ws[cellRef] = cell;
    }
  }
  if (range.s.c < 10000000) {
    ws['!ref'] = XLSX.utils.encode_range(range);
  }
  return ws;
};

export const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export function export2Excel(sheets: { header: string[], data: any, wsName: string, merges: any[], autoWidth: boolean, }[], filename: string, bookType = 'xlsx') {
  const wb = new Workbook();

  sheets.forEach(sheet => {
    sheet.data = [...sheet.data];
    sheet.data.unshift(sheet.header);

    const ws = sheetFromDataArray(sheet.data);

    if (sheet.merges.length > 0) {
      if (!ws['!merges']) {
        ws['!merges'] = [];
      }
      sheet.merges.forEach(item => {
        ws['!merges'].push(XLSX.utils.decode_range(item));
      });
    }

    const range = XLSX.utils.decode_range(ws['!ref']); /// RESTITUISCE TUTTE LE CELLE VALORIZZATE NELL'EXCEL COME XLSX.Range
    for (let R = (range.s.r); R < 1; ++R) { /// STILIZZO LE CELLE DELL'HEADER
      /// START - STILIZZAZIONE HEADER
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) {
          ws[cell_ref] = { v: '', t: 's', z: '' }
        }
        ws[cell_ref].s = {
          font: {
            name: 'Arial',
            sz: 9,
            bold: true,
            italic: false
          },
          alignment: {
            vertical: 'center',
            horizontal: 'left'
          },
          border: {
            top: { style: 'thin', color: { rgb: '00000000' } },
            bottom: { style: 'thin', color: { rgb: '00000000' } },
            right: { style: 'thin', color: { rgb: '00000000' } },
            left: { style: 'thin', color: { rgb: '00000000' } }
          },
          fill: { fgColor: { rgb: 'FFF0F0F0' } }
        }
      }
    }

    for (let R = (range.s.r + 1); R <= range.e.r; ++R) { /// STILIZZAZIONE CUSTOM PER QUESTIONARI
      /// START - STILIZZAZIONE CELLE TABELLA
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) {
          ws[cell_ref] = { v: '', t: 's', z: '' }
        }
        ws[cell_ref].s = {
          font: {
            name: 'Arial',
            sz: 9,
            bold: false,
            italic: false
          },
          alignment: {
            vertical: 'center',
            horizontal: 'left'
          }
        }
      }
    }

    if (sheet.autoWidth) {
      const colWidth = sheet.data.map((row: any) =>
        row.map((val: any) => {
          if (val == null) {
            return {
              wch: 10
            };
          } else if (val.toString().charCodeAt(0) > 255) {
            return {
              wch: val.toString().length * 2
            };
          } else {
            return {
              wch: val.toString().length
            };
          }
        })
      );

      const result = colWidth[0];
      for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
          if (result[j]?.wch && result[j]?.wch < colWidth[i][j].wch) {
            result[j].wch = colWidth[i][j].wch;
          }
        }
      }
      ws['!cols'] = result;
    }

    // Add worksheet to workbook
    if (!wb.SheetNames.includes(sheet.wsName)) {
      wb.SheetNames.push(sheet.wsName)
    }
    wb.Sheets[sheet.wsName] = ws
  });

  const wbout = XLSX.write(wb, {
    bookType: bookType as any,
    bookSST: false,
    type: 'binary',
    cellStyles: true
  })

  saveAs(new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  }), `${filename}.${bookType}`)
}
