import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class CROGService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(params?: ente.queryParams): Promise<number> {
        return await this.Get<number>(`/api/anagrafica/ordini-regionali/count`, params);
    }

    async List(params?: ente.queryParams): Promise<ente.crog[]> {
        return await this.Get<ente.crog[]>(`/api/anagrafica/ordini-regionali`, params);
    }

    async Detail(id: string): Promise<ente.crog> {
        return await this.Get<ente.crog>(`/api/anagrafica/ordini-regionali/${id}`);
    }

    async Set(obj: ente.crog): Promise<ente.crog> {
        return await this.Post<ente.crog>(`/api/anagrafica/ordini-regionali`, obj);
    }

    async Remove(id: string): Promise<void> {
        return await this.Delete(`/api/anagrafica/ordini-regionali/${id}`);
    }
}

export const crogService = new CROGService();