import { formatTime, uppercaseFirstChar } from "@/filters";
import { crogService } from "@/modules/enti/service/crogService";
import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";
import { export2Excel } from "@/utils/excel";
import { categorieGiornalista } from "../@types/giornalistiEnums";
import i18n from "@/lang";

export default class AnagraficaService extends baseRestService {
  constructor() {
    super();

    this.baseUrl = () => settings.baseUrl;
  }

  async Count(params?: giornalisti.queryParams): Promise<number> {
    return await this.Get('/api/anagrafica/giornalisti/count', params);
  }

  async List(params?: giornalisti.queryParams): Promise<giornalisti.summary[]> {
    return await this.Get('/api/anagrafica/giornalisti', params);
  }

  async Minimized(params?: giornalisti.queryParamsMinimized): Promise<giornalisti.minimized[]> {
    return await this.Get('/api/anagrafica/giornalisti/minimized', params);
  }

  async MinimizedFromFiscalCodes(params?: giornalisti.queryParamsMinimized): Promise<giornalisti.minimized[]> {
    return await this.Post('/api/anagrafica/giornalisti/minimized/from-fiscal-codes', params);
  }

  async Detail(id: string): Promise<giornalisti.item> {
    return await this.Get(`/api/anagrafica/giornalisti/${id}`);
  }

  async DetailByCodiceFiscale(cf: string): Promise<giornalisti.item> {
    return await this.Get(`/api/anagrafica/giornalisti/codice-fiscale/${cf}`);
  }

  async RemoveFromBlacklist(id: string): Promise<giornalisti.item> {
    return await this.Delete(`/api/anagrafica/giornalisti/${id}/blacklist`);
  }

  public async Export(params?: giornalisti.queryParams) {
    const giornalisti = await this.Get<giornalisti.exportItem[]>('/api/anagrafica/giornalisti/export', params);
    const entiMap = new Map<string, string>();
    (await crogService.List({ page: 0, take: -1 })).forEach(f => entiMap.set(f.id, f.descrizione));

    const __filename = `${`iscritti-odg`}${params.anagraficaEnteId ? '-' + entiMap.get(params.anagraficaEnteId) : ''}_${formatTime(new Date(), 'YYYY-MM-DD_HH-mm-ss')}`;

    const tables: any[] = [];
    tables.push({
      header: [
        'id',
        'cognome',
        'nome',
        'codice_fiscale',
        'data_nascita',
        'data_prima_iscrizione',
        'data_iscrizione_categoria',
        'categoria',
        'ordine',
        'provvedimentiDisciplinari',
        'dataProvvedimento',
        'dataInizioRinuncia',
        'dataFineRinuncia',
        'dataInizioCancellazione',
        'dataFineCancellazione',
        'dataPensione',
        'dataDecesso',
        'dataCaricamento'
      ],
      data: (giornalisti || []).map((m) => [
        m.id,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        !m.dataNascita || (m.dataNascita && m.dataNascita.getFullYear() > 1800) ? m.dataNascita : null,
        !m.dataPrimaIscrizione || (m.dataPrimaIscrizione && m.dataPrimaIscrizione.getFullYear() > 1900) ? m.dataPrimaIscrizione : null,
        !m.dataIscrizioneCategoria || (m.dataIscrizioneCategoria && m.dataIscrizioneCategoria.getFullYear() > 1900) ? m.dataIscrizioneCategoria : null,
        this.decodeCategoria(m.categoria),
        entiMap.get(m.anagraficaEnteId),
        m.provvedimentiDisciplinari,
        m.dataProvvedimento,
        m.dataInizioRinuncia,
        m.dataFineRinuncia,
        m.dataInizioCancellazione,
        m.dataFineCancellazione,
        m.dataPensione,
        m.dataDecesso,
        m.dataCaricamento
      ]),
      wsName: `esportazione_iscritti_odg`,
      merges: [],
      autoWidth: true
    });

    export2Excel(tables, __filename);
  }
  public async ExportAdempienze(params?: giornalisti.queryParams, periodoFormativo?: periodoFormativo.item) {

    const giornalisti = await this.Get<giornalisti.exportItemAdempienze[]>('/api/anagrafica/giornalisti/exportAdempienza', params);
    const entiMap = new Map<string, string>();
    (await crogService.List({ page: 0, take: -1 })).forEach(f => entiMap.set(f.id, f.descrizione));

    const __filename = `${`iscritti-odg`}${params.anagraficaEnteId ? '-' + entiMap.get(params.anagraficaEnteId) : ''}${periodoFormativo ? `_${periodoFormativo.dataInizio}-${periodoFormativo.dataFine}` : ''}_${formatTime(new Date(), 'YYYY-MM-DD_HH-mm-ss')}`;

    const tables: any[] = [];
    var result = {
      header: [
        'id',
        'cognome',
        'nome',
        'codice_fiscale',
        'data_nascita',
        'mail',
        'pec',
        'provvedimenti_disciplinari',
        'data_provvedimento',
        'data_prima_iscrizione',
        'data_inizio_cancellazione',
        'data_inizio_rinuncia',
        'data_pensione',
        'data_decesso',
        'data_iscrizione_categoria',
        'categoria',
        'ordine',
        'crediti_target',
        'crediti_totali',
        'adempienza_triennio_precedente_salvata_SuDB',
        'adempienza_triennio_selezionato',
        'crediti',
        'crediti_deontologici',
        'crediti_esenzione_totali',
        'crediti_esenzione',
        'crediti_esenzione_deontologici',
      ],
      data: [],
      wsName: `esportazione_iscritti_odg`,
      merges: [],
      autoWidth: true
    };

    for (const m of giornalisti) {
      result.data.push([
        m.id,
        m.cognome,
        m.nome,
        m.codiceFiscale,
        this.cleanData(m.dataNascita),
        m.mail,
        m.pec,
        m.provvedimentiDisciplinari,
        this.cleanData(m.dataProvvedimento),
        this.cleanData(m.dataPrimaIscrizione),
        this.cleanData(m.dataInizioCancellazione),
        this.cleanData(m.dataInizioRinuncia),
        this.cleanData(m.dataPensione),
        this.cleanData(m.dataDecesso),
        this.cleanData(m.dataIscrizioneCategoria),
        this.decodeCategoria(m.categoria),
        entiMap.get(m.anagraficaEnteId),
        m.creditiTarget,
        m.creditiTotali,
        m.adempiente == 2 ? 'Nuovo Iscritto' : m.adempiente == 1 ? 'Adempiente' : 'Non adempiente',
        (m.creditiDeontologici + m.creditiEsenzioneDeontologici) >= 20 && (m.crediti + m.creditiDeontologici >= m.creditiTarget) ? 'Si' : 'No',
        m.crediti,
        m.creditiDeontologici,
        m.creditiEsenzione + m.creditiEsenzioneDeontologici,
        m.creditiEsenzione,
        m.creditiEsenzioneDeontologici,
      ]);
    }
    tables.push(result);
    export2Excel(tables, __filename);
  }

  private decodeCategoria(cat: string): string {
    debugger
    if (!cat) return cat;

    if (cat.indexOf('_SIGEF') >= 0)
      cat = cat.replace(/_SIGEF/gi, '');
    return `${i18n.t(`anagrafica.giornalisti.categoria.${cat}`)}`
  }

  private cleanData(data: Date): Date | null {
    return !data || (data && data.getFullYear() > 1800) ? data : null
  }
}

export const anagraficaService = new AnagraficaService();